import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://mcmurdo.app.1life.com',
  auth0: {
    audience: 'https://onemedical.com',
    clientId: 'xmxONSpNNgY585DE4qqxuMmKYqWK1Z6A',
    domain: 'amazontesting-onemedical.us.auth0.com',
  },
  myoneServer: 'https://mcmurdo.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-mcmurdo',
  },
  launchDarklyClientId: '605034849a881a0c380e4ae3',
  stripePublishableKey: 'pk_test_LYcNRmAVxdwSnjPXrKcnpApT',
  appSync: {
    apiUrl: 'https://alidh3nnbncbfop2jnrcqisrga.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    enabled: true,
  },
  amazonUrls: {
    purchaseMembership: 'https://health.integ.amazon.com/prime',
    manageMembership: 'https://health.integ.amazon.com/prime/onemedical/settings',
  },
};
