import { gql } from 'apollo-angular';

export const onboardingTasks = gql`
  fragment onboardingTasks on Patient {
    onboardingTasks {
      id
      name
      status
      __typename
    }
  }
`;

export const upcomingAndCurrentAppointments = gql`
  fragment upcomingAndCurrentAppointments on Appointment {
    id
    isRemote
    startAt
    endAt
    timezoneTzinfo
    joinLink
    travelDirectionsLink
    provider {
      id
      firstName
      lastName
      displayName
      profileImageUrl
    }
  }
`;

export const pastAndCurrentAppointments = gql`
  fragment pastAndCurrentAppointments on Appointment {
    id
  }
`;

export const appState = gql`
  fragment appState on PatientAppState {
    webShowDownloadMobileAppHero
  }
`;

export const homePatient = gql`
  fragment homePatient on Patient {
    id
    timezoneTzinfo
    appState {
      ...appState
    }
    upcomingAndCurrentAppointments: appointments(strategies: [UPCOMING, CURRENT], sortOrder: ASC) {
      nodes {
        ...upcomingAndCurrentAppointments
      }
    }
    pastAndCurrentAppointments: appointments(strategies: [PAST, CURRENT], first: 1, sortOrder: DESC) {
      nodes {
        ...pastAndCurrentAppointments
      }
    }
    isPediatric
    profileInfo {
      preferredName
    }
    isVirtual
    hasBeenSeen(remotely: true)
    membership {
      isDpc
    }
    ...onboardingTasks
  }
  ${onboardingTasks}
  ${upcomingAndCurrentAppointments}
  ${pastAndCurrentAppointments}
  ${appState}
`;
